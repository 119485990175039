<!-- 月份统计 -->
<template>
  <div class="month-statistics">
    <el-descriptions
      class="margin-top"
      title="月统计"
      size="mini"
      :column="2"
      border
    >
    <el-descriptions-item label="需打卡天数">{{ data.needClockInDaysCount }}</el-descriptions-item>
    <el-descriptions-item label="需打卡时长">{{ data.needTotalDuration }}</el-descriptions-item>
    <el-descriptions-item label="已打卡天数">{{ data.clockInDaysCount }}</el-descriptions-item>
    <el-descriptions-item label="已打卡时长">{{ data.totalDuration }}</el-descriptions-item>
    <el-descriptions-item label="剩余打卡天数">{{ residueClockInDaysCount }}</el-descriptions-item>
    <el-descriptions-item label="剩余卡时长">{{ residueTotalDuration }}</el-descriptions-item>
    <el-descriptions-item label="平均剩余每天打卡时长">{{ avgResidueDaysDuration }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: "MonthStatistics",
  props: {
    data: {
        type: Object,
        default: () => ({})
    }
  },
  computed: {
    residueClockInDaysCount() {
        return this.data.needClockInDaysCount - this.data.clockInDaysCount
    },
    residueTotalDuration() {
        return this.data.needTotalDuration - this.data.totalDuration
    },
    avgResidueDaysDuration() {
        return this.residueClockInDaysCount ? this.residueTotalDuration / this.residueClockInDaysCount : 0
    },
  }
};
</script>

<style lang="scss" scoped>
</style>